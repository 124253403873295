import React, { useEffect } from 'react';
import './css/landing.css'; // Import your landing page styles
import LogoMixed from './img/Logo_mixed.png';
import TelegramIcon from './img/telegram.svg';
import InstagramIcon from './img/instagram.svg';
import TwitterIcon from './img/twitter.svg';
import MetaMetIcon from './img/MetaMet_icon.svg';
import BannerImage from './img/banner.png';
import CoinImage from './img/Coin.png';
import ArrowImage from './img/arrow.png';
import AssetImage from './img/roulette-2nd.png';
import ThreeWinnersImage from './img/3winners.png';
import UpdatesImage from './img/updates.png';
import TutorialImage from './img/tutorial.png';
import JoinChannelImage from './img/joinchannel.png';
import MetaMetLogo from './img/metamet_logo.png';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import '@iconscout/unicons/css/line.css';

const Landing = () => {
  useEffect(() => {
    new Swiper('.swiper', {
      effect: "slide",
      grabCursor: true,
      spaceBetween: 20,
      initialSlide: 1,
      loop: true,
      slidesPerView: 1,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true, // Optional: makes scrollbar draggable
      },
      breakpoints: {
        800: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        700: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        400: {
          slidesPerView: 1,
          spaceBetween: 10,
        }
      }
    });
  }, []);

  return (
    <div className="landing-page">
            <div className="noise-overlay"></div> {/* Noise overlay */}
    <section className="cryptola__container">
      <div className="cryptola__header">
        <div className="cryptola__header-logo">
          <img src={LogoMixed} width="100" className="cryptola__header-logo__img" alt="Logo" />
        </div>
        <div className="cryptola__header-launches">
          <div className="cryptola__header-launches_button">
            <i className="uil uil-info-circle"></i>
            Cryptola Launches Within Telegram Only
          </div>
        </div>
        <div className="cryptola__header-login">
          <a href="https://t.me/Cryptolacc_bot?startapp" target="_blank" className="cryptola__header-login__button">
            <i className="uil uil-telegram-alt"></i> Login with Telegram
          </a>
        </div>
      </div>

      <div className="cryptola__content">
        <div className="cryptola__content-box01">
          <div className="cryptola__content-box01_01">
            <h3 className="cryptola__content-box01_01__h3">YOUR 1$? A WORLD OF POSSIBILITIES!</h3>
            <p className="cryptola__content-box01_01__p">WHY WAIT? JOIN THE EXCLUSIVE DRAW NOW!</p>
            <a href="https://t.me/Cryptolacc_bot?startapp" target="_blank" className="cryptola__content-box01_01__a"><i className="uil uil-telegram-alt"></i> Start Cryptola</a>
            <div className="cryptola__content-box01_01__listOr">
              <span className="cryptola__content-box01_01__listOr-spn1 cryptola__content-box01_01__listOr-spn11"></span>
              <span className="cryptola__content-box01_01__listOr-spn2">OR</span>
              <span className="cryptola__content-box01_01__listOr-spn1"></span>
            </div>
            <ul className="cryptola__content-box01_01__listUl">
              <li><a href="https://t.me/cryptola_cc" target="_blank"><img src={TelegramIcon} alt="telegram" width="40" /></a></li>
              <li><a href="https://www.instagram.com/cryptola.cc" target="_blank"><img src={InstagramIcon} alt="instagram" width="40" /></a></li>
              <li><a href="#" target="_blank"><img src={TwitterIcon} alt="twitter" width="40" /></a></li>
              <li><a href="https://metamet.co" target="_blank"><img src={MetaMetIcon} alt="MetaMet" width="40" /></a></li>
            </ul>
          </div>
          <img className="cryptola__content-box01_02" src={BannerImage} width="60" alt="Banner" />
          <img src={CoinImage} width="20" className="coin01 coin00" alt="Coin" />
          <img src={CoinImage} width="20" className="coin02 coin00" alt="Coin" />
          <img src={CoinImage} width="20" className="coin03 coin00" alt="Coin" />
          <img src={CoinImage} width="20" className="coin04 coin00" alt="Coin" />
          <img src={CoinImage} width="20" className="coin05 coin00" alt="Coin" />
          <img src={CoinImage} width="20" className="coin06 coin00" alt="Coin" />
        </div>

        <div className="swiper cryptola__content-box02">
          <div className="swper-title">
            SWIPE TO SEE MORE <img src={ArrowImage} alt="Arrow" />
          </div>
          <div className="swiper-wrapper">
            <div className="swiper-slide cryptola__content-box02-1 cryptola__content-box02-0">
              <div className="cryptola__content-box02-0__1">
                <p>SAME ODD SYSTEM</p>
                <h3>NO DISADVANTAGE</h3>
                <h3>WHATSOEVER</h3>
              </div>
              <img src={AssetImage} width="60" alt="Asset" className="cryptola__content-box02-0__2" />
            </div>
            <div className="swiper-slide cryptola__content-box02-2 cryptola__content-box02-0">
              <div className="cryptola__content-box02-0__1">
                <p>WEEKLY / MONTHLY DRAW</p>
                <h3>WITH THREE</h3>
                <h3>LUCKY WINNERS</h3>
              </div>
              <img src={ThreeWinnersImage} width="60" alt="Three Winners" className="cryptola__content-box02-0__2" />
            </div>
            <div className="swiper-slide cryptola__content-box02-3 cryptola__content-box02-0">
              <div className="cryptola__content-box02-0__1">
                <p>REAL-TIME LIVE UPDATES</p>
                <h3>SO YOU WON'T</h3>
                <h3>MISS ANY CHANCE</h3>
              </div>
              <img src={UpdatesImage} width="60" alt="Updates" className="cryptola__content-box02-0__2" />
            </div>
          </div>
          <div className="swiper-scrollbar"></div>
        </div>

        <div className="cryptola__content-box03">
          <div className="cryptola__content-box03-0">
            <div className="cryptola__content-box03-0-1">
              <p>NEW TO CRYPTO</p>
              <h3>WE HAVE CRYPTOLA INTRODUCTION</h3>
              <h3>TUTORIAL AVAILABLE FOR YOU!</h3>
              <div className="cryptola__content-box03-0-1div">
                <a href="#" target="_blank">Watch Tutorials</a>
              </div>
            </div>
            <img src={TutorialImage} width="60" className="cryptola__content-box03-0-2" alt="Tutorial" />
          </div>
          <div className="cryptola__content-box03-0">
            <div className="cryptola__content-box03-0-1">
              <p>NOT SURE IF YOU WANT TO PARTICIPATE?</p>
              <h3>SIMPLY JOIN OUR GROUP AND KEEP</h3>
              <h3>AN EYE NOW HOW IT GOES!</h3>
              <div className="cryptola__content-box03-0-1div">
                <a href="https://t.me/cryptola_cc" target="_blank">Join Channel</a>
              </div>
            </div>
            <img src={JoinChannelImage} className="cryptola__content-box03-0-2" width="60" alt="Join Channel" />
          </div>
        </div>

        <div className="cryptola__content-box04">
          <p>CRYPTOLA 2024 | POWERED BY</p>
          <img src={MetaMetLogo} alt="MetaMet Logo" width="60" />
        </div>
      </div>
    </section>
    </div>
  );
};

export default Landing;
