import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/style3.css';
import './css/Loader.css';
import Loader from './Loader';

function AdminDashboard() {
  const [settings, setSettings] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drawDate, setDrawDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [paidUsersCount, setPaidUsersCount] = useState(0);
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [adminPassword, setAdminPassword] = useState('');
  const [broadcastMessage, setBroadcastMessage] = useState(''); // For broadcast message
  const [imageUrl, setImageUrl] = useState(''); // New state for image URL

  const getToken = () => {
    const token = localStorage.getItem('authToken');
    console.log('Retrieved token from localStorage:', token);
    return token;
  };

  useEffect(() => {
    const token = getToken();

    if (!token || token.split('.').length !== 3) {
      console.error('Authorization token is missing or invalid JWT format.');
      toast.error('Authorization token is missing. Please log in again.');
      setLoading(false);
      return;
    }

    const fetchSettings = async () => {
      try {
        const response = await axios.get('/api/admin/settings', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSettings(response.data || {});

        if (response.data.drawDate) {
          setDrawDate(new Date(response.data.drawDate).toISOString().slice(0, -1));
        }

        setPaidUsersCount(response.data.paidUsersCount || 0);
      } catch (error) {
        console.error('Error fetching settings:', error.response ? error.response.data : error.message);
        toast.error('Error fetching settings');
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`/api/admin/users?page=${currentPage}&limit=10`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.users);
        setTotalUsers(response.data.totalUsers);
      } catch (error) {
        console.error('Error fetching users:', error.response ? error.response.data : error.message);
        toast.error('Error fetching users');
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
    fetchUsers();
  }, [currentPage]);

  const updateSettings = async () => {
    const token = getToken();
    try {
      await axios.post('/api/admin/settings', settings, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Settings updated successfully');
    } catch (error) {
      toast.error('Error updating settings');
    }
  };

  const setDrawDateHandler = async () => {
    const token = getToken();
    if (!drawDate) {
      toast.warn('Please set a valid draw date');
      return;
    }

    try {
      await axios.post('/api/admin/drawDate', { drawDate }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Draw date set successfully');
    } catch (error) {
      toast.error('Error setting draw date');
    }
  };

  const performDraw = async () => {
    const token = getToken();
    try {
      await axios.post('/api/admin/draw', {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Draw performed successfully');
    } catch (error) {
      toast.error('Error performing draw');
    }
  };
  const resetTombola = async () => {
    if (!adminPassword) {
      toast.warn('Please enter the admin password.');
      return;
    }
  
    const token = getToken();
    try {
      console.log('Sending reset request'); // Add log here
      await axios.post('/api/admin/reset', { password: adminPassword }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Tombola reset successfully');
      setShowResetPopup(false);
    } catch (error) {
      console.error('Error resetting tombola:', error); // Log the error here
      toast.error('Error resetting tombola');
    }
  };
  

  const toggleResetPopup = () => {
    console.log('Popup state:', showResetPopup);
    setShowResetPopup(!showResetPopup);
  };

  // Function to handle broadcast message with optional image URL
  const handleBroadcastMessage = async () => {
    const token = getToken();
    if (!broadcastMessage.trim() && !imageUrl.trim()) {
      toast.warn('Please enter a valid message or provide an image URL.');
      return;
    }

    try {
      await axios.post('/api/admin/broadcast', { message: broadcastMessage, imageUrl: imageUrl }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Message broadcasted successfully');
      setBroadcastMessage(''); // Clear the text area after sending
      setImageUrl(''); // Clear the image URL after sending
    } catch (error) {
      toast.error('Error broadcasting message');
    }
  };


  return (
    <div>
      <ToastContainer />

      {/* Reset Tombola Popup */}
      {showResetPopup && (
        <div id="allertSave">
          <div className="allertSave00">
          <button className="close-button" onClick={toggleResetPopup}>&times;</button>
            <h3>Reset Tombola Settings</h3>
            <p>Please enter the administrator password to perform your tombola reset, this action is irreversible.</p>
            <div className="allertSave00_01">
              <label htmlFor="adminPassword">Enter admin Password:</label>
              <input
                type="password"
                id="adminPassword"
                placeholder="Password"
                value={adminPassword}
                onChange={(e) => setAdminPassword(e.target.value)}
              />
            </div>
            <div className="allertSave00_02">
              <button onClick={resetTombola}>Perform Reset</button>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <Loader />
      ) : (
        <section className="body00">
          <div className="tmb03_01">
            <h3>Administration</h3>
          </div>

          {/* Tombola Controls Section */}
          <div className="tmb03_02">
            <ul>
              <li>
                <span>Target Count</span>
                <input
                  type="number"
                  placeholder="Target Count"
                  value={settings.userBaseGoal || ''}
                  onChange={(e) => setSettings({ ...settings, userBaseGoal: e.target.value })}
                />
              </li>
              <li>
                <span>Payments Toggle</span>
                <select
                  value={settings.paymentsOpen ? 'Yes' : 'Non'}
                  onChange={(e) => setSettings({ ...settings, paymentsOpen: e.target.value === 'Yes' })}
                >
                  <option value="Yes">Yes</option>
                  <option value="Non">No</option>
                </select>
              </li>
              <li>
                <span>Registrations Toggle</span>
                <select
                  value={settings.registrationsOpen ? 'Yes' : 'Non'}
                  onChange={(e) => setSettings({ ...settings, registrationsOpen: e.target.value === 'Yes' })}
                >
                  <option value="Yes">Yes</option>
                  <option value="Non">No</option>
                </select>
              </li>
            </ul>
            <div className="svsttt">
              <button onClick={updateSettings}>Save settings</button>
            </div>
          </div>
          <br />

          {/* Broadcast Message Section */}
          <div className="tmb03_02">
            <ul>
              <li>
                <span>Message</span>
                <textarea
                  placeholder="Enter your broadcast message here"
                  value={broadcastMessage}
                  onChange={(e) => setBroadcastMessage(e.target.value)}
                />
              </li>
              <li>
                <span>Image URL </span>
                <input
                  type="text"
                  placeholder="Enter image URL here"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                />
              </li>
            </ul>
            <div className="svsttt">
              <button onClick={handleBroadcastMessage}>Broadcast Message</button>
            </div>
          </div>
          <br />

          {/* Draw Date Section */}
          <div className="tmb03_03">
            <div className="tmb03_03-1">
              <span>Draw date</span>
              <div className="tmb03_03-1-0">
                <input
                  type="date"
                  value={drawDate}
                  onChange={(e) => setDrawDate(e.target.value)}
                  placeholder="DD/MM/YYYY"
                />
              </div>
            </div>
            <div className="svsttt">
              <button onClick={setDrawDateHandler}>Save settings</button>
            </div>
          </div>
          <br />
          {/* Draw and Reset Controls */}
          <div className="tmb03_04">
            <button onClick={performDraw}>Perform Draw</button>
            <button onClick={toggleResetPopup}>Reset Tombola</button>
          </div>
          <br />
          {/* User Database Section */}
          <div className="tmb03_05">
          <div className="tmb03_05-1">
          <span className="tmb03_05-1_01">User Database</span>
            </div>
            <div className="tmb03_05-1">
            <span className="tmb03_05-1_02">Paid User Count: <span>{paidUsersCount}</span></span>
            <span className="tmb03_05-1_02">User Count: <span>{totalUsers}</span></span>
            </div>

            {/* User Table */}
            <div id="tmb03_05-2">
              <table id="product-table">
                <thead>
                  <tr>
                    <th>Full name</th>
                    <th>Email</th>
                    <th>Telegram User</th>
                    <th>Telegram ID</th>
                    <th>Payment Status</th>
                    <th>Phone</th>
                    <th>Gender</th>
                    <th>Country</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user._id}>
                      <td>{user.fullname}</td>
                      <td>{user.email}</td>
                      <td>{user.telegramUsername}</td>
                      <td>{user.telegramId}</td>
                      <td>{user.paymentStatus}</td>
                      <td>{user.phone}</td>
                      <td>{user.gender}</td>
                      <td>{user.country}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="pagination-controls">
              <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>
                Previous
              </button>
              <span id="page-info">Page {currentPage} of {Math.ceil(totalUsers / 10)}</span>
              <button onClick={() => setCurrentPage((prev) => prev + 1)} disabled={currentPage * 10 >= totalUsers}>
                Next
              </button>
            </div>
          </div>
          <br /><br /><br />
        </section>
      )}
    </div>
  );
}

export default AdminDashboard;
