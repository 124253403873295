// frontend/src/components/UserDashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './css/style2.css';
import './css/Loader.css';
import Loader from './Loader';
import Logo_mixed from './img/Logo_mixed.png';
import Avatar1 from './img/Avatar_male.png';
import Avatar2 from './img/Avatar_female.png';
import Avatar3 from './img/Avatar_other.png';
import Dollar from './img/Dollar.png';
import User2 from './img/User2.png';
import Bank from './img/Bank.png';
import Coin from './img/Coin.png';
import Coin2 from './img/Coin2.png';
import Trophy from './img/Trophy.png';

function UserDashboard() {
  const [userData, setUserData] = useState({});
  const [tombolaData, setTombolaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentLink, setPaymentLink] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          setError('Authorization token is missing. Please log in again.');
          setLoading(false);
          return;
        }

        const telegramUserId = window.Telegram.WebApp?.initDataUnsafe?.user?.id;
        if (!telegramUserId) {
          console.error('No Telegram user ID found in initDataUnsafe.');
          setError('Unable to retrieve user data from Telegram.');
          setLoading(false);
          return;
        }

        const userResponse = await axios.get(`/api/user/me?telegramId=${telegramUserId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setUserData(userResponse.data);

        // Fetch tombola data, including drawCompensation and prize calculations
        const tombolaResponse = await axios.get('/api/tombola/data', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        setTombolaData(tombolaResponse.data);

        if (tombolaResponse.data.paymentsOpen && userResponse.data.paymentStatus === 'Unpaid') {
          const amount = 1;
          const fee = 0.05;
          const totalAmount = (amount + fee).toFixed(2);

          try {
            const paymentResponse = await axios.post('/api/payment/request', { amount: totalAmount, telegramId: telegramUserId }, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
            if (paymentResponse.data && paymentResponse.data.bot_invoice_url) {
              setPaymentLink(paymentResponse.data.bot_invoice_url);
              setPaymentAmount(totalAmount);
            }
          } catch (paymentError) {
            console.error('Error creating payment request:', paymentError);
            setError('Failed to create payment request. Please try again later.');
          }
        }

      } catch (fetchError) {
        console.error('Error fetching data:', fetchError);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) return <Loader />;

    // Determine which avatar to display based on the user's gender
    const getAvatar = (gender) => {
      switch (gender) {
        case 'Female':
          return Avatar2;
        case 'Other':
          return Avatar3;
        default:
          return Avatar1; // Default to male avatar
      }
    };
  

  return (
    <section className="body00">
      {error && <div style={{ color: 'red' }}>{error}</div>}

      {/* Logo Section */}
        <div className="logo_container">
          <img src={Logo_mixed} alt="Cryptola Logo" />
        </div>
      

      {/* User Info Section */}
      <div className="temp_container01">
        <div className="temp_container01-0 temp_container01-000">
          <div className="temp_container01-00">
            <img src={getAvatar(userData.gender)} alt="User Avatar" width="26" className="imgAvatar" />
            <div className="temp_container01-0-01">
              <span className="ss1">{userData.fullname}</span>
              <span className="ss2">@{userData.telegramUsername}</span>
            </div>
          </div>
          <div className="temp_container01-0-02">
            <img src={Dollar} alt="Payment Status" width="20" />
            <span>{userData.paymentStatus}</span>
          </div>
        </div>
        <div className="temp_container01-0">
          <img src={User2} alt="Registration Icon" width="21" />
          <div className="temp_container01-0-01">
            <span className="ss1">Registration</span>
            <span className="ss3">{tombolaData?.registrationsOpen ? 'On' : 'Off'}</span>
          </div>
        </div>
        <div className="temp_container01-0">
          <img src={Bank} width="21" alt="Payments Icon" />
          <div className="temp_container01-0-01">
            <span className="ss1">Payments</span>
            <span className="ss4">{tombolaData?.paymentsOpen ? 'On' : 'Off'}</span>
          </div>
        </div>
      </div>
      <br/><br/>
      {/* Tombola Progression Section */}
      <div className="temp_container02">
        <div className="temp_container02-1">
          <img src={Coin} alt="Tombola Progression" width="40" />
          <p>Raffle Progression</p>
        </div>
        <div className="temp_container02-2">
          <span>{userData.currentProgression !== undefined ? userData.currentProgression + '%' : 'N/A'}</span>
        </div>
        <div className="coinnnn">
          {[...Array(7)].map((_, index) => (
            <img key={index} src={Coin} alt="Coin Icon" width="30" className={`coin00${index + 1}`} />
          ))}
        </div>
      </div>
      <br/>
      {/* Welcome Back Section */}
      <div className="temp_container03">
        <h2>Welcome Back!</h2>
      </div>
      <br/>
      {/* Payment Options Section */}
      {userData.paymentStatus === 'Unpaid' && tombolaData?.paymentsOpen && (
        <div className="temp_container05">
          <div className="temp_container05-1">
            <h3>Payment Options</h3>
            <p>User Payment Status : <span>{userData.paymentStatus}</span></p>
          </div>
          <div className="temp_container05-2">
            <div className="temp_container05-2-01">
              <p>You need to cover the transaction fees to ensure we receive $1 in total.</p>
              <br />
              <p>Please pay <strong>${paymentAmount} (USDT)</strong> to participate.</p>
              <br />
              <div className="btnnns">
                <a href={paymentLink} target="_blank" rel="noopener noreferrer">
                  <button>Participate now</button>
                </a>
              </div>
              <img src={Coin2} alt="Coin Icon" className="temp_container05-coinnnnn" />
            </div>
          </div>
        </div>
        
      )}
      <br/><br/>
      {/* User Contact Details Section */}
      <div className="temp_container04">
        <ul className="temp_container04-ul">
          <li><span className="ssss01">Full Name</span><span className="ssss02">{userData.fullname}</span></li>
          <li><span className="ssss01">Email</span><span className="ssss02">{userData.email}</span></li>
          <li><span className="ssss01">Phone Number</span><span className="ssss02">{userData.phone}</span></li>
          <li><span className="ssss01">Country</span><span className="ssss02">{userData.country || 'N/A'}</span></li>
        </ul>
        <img src={getAvatar(userData.gender)} width="100" alt="User Avatar" className="avatrrr00" />
      </div>
      
      <br/><br/>

      {/* Tombola Details Section */}
      <div className="temp_container06">
        <div className="temp_container06-1">
          <p className="temp_container06-1_title">Raffle details</p>
          <ul>
            <li>Draw date</li>
            <li>{userData.drawDate ? new Date(userData.drawDate).toLocaleDateString() : 'Not set yet'}</li>
          </ul>
          <ul>
            <li>Draw reward</li>
            <li>{tombolaData?.drawCompensation ? `${tombolaData.drawCompensation} $` : 'Not set'}</li>
          </ul>
        </div>
        <div className="temp_container06-1">
          <p className="temp_container06-1_title">Reward Details</p>
          <ul>
            <li>First Winner</li>
            <li>{tombolaData?.firstWinnerPrize} $</li>
          </ul>
          <ul>
            <li>Second Winner</li>
            <li>{tombolaData?.secondWinnerPrize} $</li>
          </ul>
          <ul>
            <li>Third Winner</li>
            <li>{tombolaData?.thirdWinnerPrize} $</li>
          </ul>
        </div>
        <div className="temp_container06-11111">
          <img src={Trophy} width="60" alt="Trophy Icon" />
        </div>
      </div>
      <br/><br/>
    </section>
  );
}

export default UserDashboard;