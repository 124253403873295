import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Landing from './landing.js';
import './css/style.css'; // Import the CSS file
import './css/Loader.css'; // Create a separate CSS file for loader styles
import Loader from './Loader'; // Import the Loader component
import Logo_white from './img/Logo_white.png';
import LogoCoin from './img/Coin.png';
import UserIcon from './img/user.png';
import Gender from './img/Gender.png'
import EmailIcon from './img/Letter.png';
import PhoneIcon from './img/Book.png';
import CountryIcon from './img/Globe.png';

import UserDashboard from './UserDashboard';
import AdminDashboard from './AdminDashboard';

// Import country options
import countryList from 'react-select-country-list'; // Add this line to import the country list library

function RegistrationPage() {
  const [formData, setFormData] = useState({ fullname: '', email: '', phone: '', country: '', gender: '' }); // Add gender state
  const [telegramData, setTelegramData] = useState({});
  const [isRegistered, setIsRegistered] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const formRef = useRef(null); // Reference for the form container
  const pageRef = useRef(null); // Reference for the entire page
  const countries = countryList().getData(); // Fetch the list of countries

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      const initData = window.Telegram.WebApp.initDataUnsafe;

      if (initData && initData.user) {
        const telegramUserData = {
          username: initData.user.username,
          id: initData.user.id,
        };
        setTelegramData(telegramUserData);

        // Check if the user is already registered
        axios.post('/api/user/auth/verify', { id: telegramUserData.id })
          .then((response) => {
            setIsRegistered(response.data.registered);
            setIsAdmin(response.data.isAdmin || false);
            setLoading(false);
          })
          .catch((error) => {
            setError('Error checking registration status. Please try again.');
            setLoading(false);
          });
      } else {
        setError('No user data available from Telegram.');
        setLoading(false);
      }
    } else {
      setError('Telegram WebApp SDK is not available.');
      setLoading(false);
    }
  }, []);

  // Adjust page height on keyboard appearance
  useEffect(() => {
    const adjustHeight = () => {
      if (pageRef.current) {
        pageRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    // Adjust height on mount and when the keyboard is triggered
    adjustHeight();
    window.addEventListener('resize', adjustHeight);

    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);

  // Handle input focus to scroll the element into view
  const handleFocus = (e) => {
    e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegister = (event) => {
    event.preventDefault();

    const dataToSend = {
      ...formData,
      telegramUsername: telegramData.username,
      telegramId: telegramData.id,
    };

    axios.post('/api/user/auth/register', dataToSend)
      .then((response) => {
        if (response.data.success) {
          setIsRegistered(true);
        } else {
          setError('Registration failed. Please try again.');
        }
      })
	.catch((error) => {
	  if (error.response) {
		console.error("Registration error response:", error.response.data);
		setError(`Error registering user: ${error.response.data.error || error.message}`);
	  } else if (error.request) {
		console.error("Registration error request:", error.request);
		setError("No response from the server. Please check your network connection.");
	  } else {
		console.error("Registration error:", error.message);
		setError(`Error registering user: ${error.message}`);
	  }
	});
  };

  if (loading) return <Loader />;

return (
  <div className="registration-page" ref={pageRef}>
    {error === "No user data available from Telegram." ? (
      <Landing />
    ) : (
      <>
        {error && <div className="error-message">{error}</div>}
        {isRegistered ? (
          isAdmin ? (
            <AdminDashboard />
          ) : (
            <UserDashboard />
          )
        ) : (
          <section className="body00">
            <div className="banner_container">
              <div className="banner_container-1">
                <div className="banner_container-1_01">
                  <span className="s1">Join <span className="s2">Cryptola</span></span>
                  <span className="s1">and Try your luck</span>
                  <span className="s1">It Costs <span className="s2">only 1$</span></span>
                </div>
                <div>
                  <img src={Logo_white} alt="Logo_white" width="60" />
                </div>
              </div>
              <img src={LogoCoin} alt="Logo-coin" className="banner_container-2" />
            </div>

            <div className="title_container">
              <span>Sign up</span>
            </div>
            <br /><br />
            <div className="forms_container">
              <form onSubmit={handleRegister} className="registration-form" ref={formRef}>
                <div className="forms_container-00">
                  <label className="forms_container-00_label">Full name</label>
                  <div className="forms_container-00_div">
                    <input
                      type="text"
                      name="fullname"
                      placeholder="Enter your full name"
                      value={formData.fullname}
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      required
                    />
                    <div className="boxImg">
                      <img src={UserIcon} alt="User Icon" width="20" />
                    </div>
                  </div>
                </div>
                <br />
                {/* Add Gender Field */}
                <div className="forms_container-00">
                  <label className="forms_container-00_label">Gender</label>
                  <div className="forms_container-00_div">
                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      required
                    >
                      <option value="" disabled>Select your gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    <div className="boxImg">
                      <img src={Gender} alt="Gender Icon" width="20" />
                    </div>
                  </div>
                </div>
                <br />
                <div className="forms_container-00">
                  <label className="forms_container-00_label">Email Address</label>
                  <div className="forms_container-00_div">
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email address"
                      value={formData.email}
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      required
                    />
                    <div className="boxImg">
                      <img src={EmailIcon} alt="Email Icon" width="20" />
                    </div>
                  </div>
                </div>
                <br />
                <div className="forms_container-00">
                  <label className="forms_container-00_label">Phone Number</label>
                  <div className="forms_container-00_div">
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Enter your phone number"
                      value={formData.phone}
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      required
                    />
                    <div className="boxImg">
                      <img src={PhoneIcon} alt="Phone Icon" width="20" />
                    </div>
                  </div>
                </div>
                <br />
                <div className="forms_container-00">
                  <label className="forms_container-00_label">Country</label>
                  <div className="forms_container-00_div">
                    <select
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      onFocus={handleFocus}
                      required
                    >
                      <option value="" disabled>Select your country</option>
                      {countries.map((country) => (
                        <option key={country.value} value={country.label}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                    <div className="boxImg">
                      <img src={CountryIcon} alt="Country Icon" width="20" />
                    </div>
                  </div>
                </div>
                <br />
                <div className="forms_container-11">
                  <button type="submit">Register</button>
                </div>
              </form>
            </div>
            <br /><br /><br /><br />
            <div className="footer_container">
              <p>This Form is protected by reCAPTCHA and the google privacy policy and terms of services apply.</p>
              <span>Powered by Telegram Mini-App</span>
            </div>
          </section>
        )}
      </>
    )}
  </div>
);
}

export default RegistrationPage;
