// frontend/src/components/Loader.js

import React from 'react';
import './css/Loader.css'; // Import the CSS file for the loader

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader" />
    </div>
  );
};

export default Loader;
