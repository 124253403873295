// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 300 150\"><path fill=\"none\" stroke=\"%23FFFFFF\" stroke-width=\"16\" stroke-linecap=\"round\" stroke-dasharray=\"300 385\" stroke-dashoffset=\"0\" d=\"M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z\"><animate attributeName=\"stroke-dashoffset\" calcMode=\"spline\" dur=\"2\" values=\"685;-685\" keySplines=\"0 0 1 1\" repeatCount=\"indefinite\"></animate></path></svg>", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* frontend/src/css/Loader.css */

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #141623; /* Updated background color */
  z-index: 1000; /* Ensure the loader is above all other content */
}

.loader {
  width: 150px;
  height: 75px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/css/Loader.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,yBAAyB;EACxC,WAAW;EACX,eAAe;EACf,MAAM;EACN,OAAO;EACP,yBAAyB,EAAE,6BAA6B;EACxD,aAAa,EAAE,iDAAiD;AAClE;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yDAAyf;EACzf,4BAA4B;EAC5B,2BAA2B;AAC7B","sourcesContent":["/* frontend/src/css/Loader.css */\r\n\r\n.loader-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 100vh; /* Full viewport height */\r\n  width: 100%;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  background-color: #141623; /* Updated background color */\r\n  z-index: 1000; /* Ensure the loader is above all other content */\r\n}\r\n\r\n.loader {\r\n  width: 150px;\r\n  height: 75px;\r\n  background-image: url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 300 150\"><path fill=\"none\" stroke=\"%23FFFFFF\" stroke-width=\"16\" stroke-linecap=\"round\" stroke-dasharray=\"300 385\" stroke-dashoffset=\"0\" d=\"M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z\"><animate attributeName=\"stroke-dashoffset\" calcMode=\"spline\" dur=\"2\" values=\"685;-685\" keySplines=\"0 0 1 1\" repeatCount=\"indefinite\"></animate></path></svg>');\r\n  background-repeat: no-repeat;\r\n  background-position: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
