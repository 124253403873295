// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import axios from 'axios';
import RegistrationPage from './components/RegistrationPage';
import UserDashboard from './components/UserDashboard';
import AdminDashboard from './components/AdminDashboard';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const telegramData = window.Telegram.WebApp.initData;
        if (telegramData) {
          const encodedInitData = encodeURIComponent(telegramData);

          const response = await axios.post('/api/auth/verify', {
            initData: encodedInitData
          });

          if (response.data.registered) {
            setUser(response.data);
          } else {
            setUser(null);
          }
        } else {
          console.error('Telegram initData is missing.');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get('/api/admin/settings', {
          headers: { Authorization: `Bearer ${token}` }
        });

        setSettings(response.data);
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };

    if (user && user.isAdmin) {
      fetchSettings();
    }
  }, [user]);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        user && !user.isAdmin ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );

  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        user && user.isAdmin ? (
          <Component {...props} settings={settings} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );

  if (loading) return <p>Loading...</p>;

  return (
    <Router>
      <div className="App">
        <Switch>
        <Route path="/" exact>
        {user ? (
          user.isAdmin ? (
            <Redirect to="/admin" />
          ) : (
            <Redirect to="/dashboard" />
          )
        ) : (
          <RegistrationPage />
        )}
      </Route>
          <PrivateRoute path="/dashboard" component={UserDashboard} />
          <AdminRoute path="/admin" component={AdminDashboard} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
